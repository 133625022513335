import Vue from 'vue'
import App from './App.vue'
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'

Vue.config.productionTip = false
Vue.prototype.$html2canvas = html2canvas
Vue.prototype.$QRCode = QRCode

new Vue({
  render: h => h(App)
}).$mount('#app')