<template>
  <div id="app">
    <div class="headers">运动计时总成绩</div>
    <div class="html2canvas-wrap">
      <div ref="area" id="aaa">
        <div class="header">运动计时总成绩</div>
        <div class="SetText">
          <div class="propertyitemFrist">
            <div class="headimg">
              <img style="width: 25px;height: auto;" src="../public/image/Neo.png" alt="">
            </div>
            <div style="flex: 1;display: flex;justify-content: flex-start;align-items: center;">
              <div style="font-size: 15px;width: auto;background-color:white;margin-right: 15rpx;">教练姓名:</div>
              <input type="text" v-model="Coach" readonly="readonly" id="inputview">
            </div>
          </div>
        </div>
        <div class="SetTextSecond">
          <div class="propertyitem">
            <div class="headimg">
              <img style="width: 25px;height: auto;" src="../public/image/runner.png" alt="">
            </div>
            <div style="flex: 1;display: flex;justify-content: flex-start;align-items: center;">
              <div style="font-size: 15px;width: auto;background-color:white;margin-right: 15rpx;">考生姓名:</div>
              <input type="text" v-model="name" readonly="readonly" id="inputview">
            </div>
          </div>
        </div>
        <div class="SetTextSecond">
          <div class="propertyitem">
            <div class="headimg">
              <img style="width: 25px;height: auto;" src="../public/image/playground.png" alt="">
            </div>
            <div style="flex: 1;display: flex;justify-content: flex-start;align-items: center;">
              <div style="font-size: 15px;width: auto;background-color:white;margin-right: 15rpx;">圈数设置:</div>
              <input type="text" v-model="coil" readonly="readonly" id="inputview">
            </div>
          </div>
        </div>
        <div class="SetTextSecond">
          <div class="propertyitem">
            <div class="headimg">
              <img style="width: 25px;height: auto;" src="../public/image/DIST.png" alt="">
            </div>
            <div style="flex: 1;display: flex;justify-content: flex-start;align-items: center;">
              <div style="font-size: 15px;width: auto;background-color:white;margin-right: 15rpx;">距离设置:</div>
              <input type="text" v-model="Distance" readonly="readonly" id="inputview">
            </div>
          </div>
        </div>


        <!-- 总成绩 -->
        <div class="totalResult">
          <div
            style="width: 100px;height: auto;display: flex;flex-direction: column;justify-content: space-around;align-items:center;">
            <img src="../public/image/win1.png" style="width: 40px;height: auto;" alt="">
            <div>
              <div style="white-space: pre-line;font-size: 16px;font-weight: bold;padding: 0px;">总</div>
              <div style="white-space: pre-line;font-size: 16px;font-weight: bold;padding: 2px;">成</div>
              <div style="white-space: pre-line;font-size: 16px;font-weight: bold;padding: 2px 2px 7px 2px;">绩</div>
            </div>
          </div>
          <!-- 数据 -->
          <div
            style="width: 100%;display: flex;flex-direction: column;align-items: flex-start;justify-content:space-evenly;margin-left: 20rpx;margin-top: 0px;">
            <div style="font-size: 16px;display: flex ;">总用时: <p style="margin-left: 10rpx;">{{ totalNumber }}</p>
            </div>
            <div style="font-size: 16px;display: flex ;">总距离: <p style="margin-left: 10rpx;">{{ totalDistance }}m</p>
            </div>
            <div style="font-size: 16px;display: flex ;">结束时间: <p style="margin-left: 10rpx;">{{ endTime }}</p>
            </div>
          </div>
        </div>

        <!-- 单圈成绩 -->
        <div style="height: auto;">
          <div
            style="font-size: 16px;text-align: center;width: 100%;color: rgb(45, 135, 170);margin-top: 15px;;margin-bottom: 15px;">
            单圈成绩
          </div>
          <div class="cell-bg" v-for="(item, index) in dataArr" :key="index">
            <div style="font-size: 15px;width: 20px;text-align: center;margin-left: 10px;margin-right: 10px;">
              {{ dataArr.length - index }}</div>
            <div class="left-div">
              <img src="../public/image/runer.png" class="img" alt="">
            </div>
            <div class="right-div">
              <div class="name">{{ item.name }}</div>
              <div class="content">用时：{{ item.time }} | 距离：{{ Distance }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 展示图片 -->
      <div class="imgBox" style="display: none" @click="closeMask">
        <div class="imgMain">
          <p style="font-size: 14px; font-weight: bold; color: rgb(88, 88, 88);">长按下图保存或分享</p>
          <div class="canvasImg">
            <img src="" alt="" id="canvasPic">
          </div>
        </div>
      </div>
      <!-- 按钮 -->
      <button style="position:fixed; right: 1rem; top: 10px; z-index: 9;z-index: 999;" type="primary"
        @click="generateImage">生成图片</button>

    </div>
  </div>
</template>


<script>
export default {
  name: 'App',
  components: {
  },
  created() {
    // 获取 URL 中的查询参数部分
    const queryString = window.location.search;
    // 将查询参数转换为对象
    const params = new URLSearchParams(queryString)
    // 使用 get 方法获取特定参数的值
    this.Coach = params.get('coach');
    this.name = params.get('name');
    this.coil = params.get('coil');
    this.Distance = params.get('distance') + 'm'
    this.totalNumber = params.get('totalnumber');
    this.totalDistance = params.get('totaldistance');
    this.endTime = params.get('endtime');
    const dataArr = JSON.parse(params.get('data'));
    this.dataArr = dataArr

    setTimeout(() => {
      this.generateImage()
    },150);
  }
  ,
  data() {
    return {
      imgUrl: '',
      totalNumber: '',
      totalDistance: '',
      endTime: '',
      Distance: '',
      Coach: '',
      name: '',
      coil: '',
      // dataArr: [{ name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }, { name: '张三', time: '9.83', Distance: '100m' }]
      dataArr: []
    }
  },
  methods: {
    generateImage() {
      const rect = this.$refs.area.getBoundingClientRect() // 关键代码
      this.$html2canvas(this.$refs.area, {
        scrollY: rect.top, // 关键代码
        height: rect.height + 50 // 加高度，避免截取不全
      }).then(canvas => {
        canvas.toBlob(blob => {
          var imgBoxEle = document.getElementsByClassName('imgBox')[0]
          imgBoxEle.style.display = "block"
          this.imgUrl = canvas.toDataURL('image/jpeg')
          const aImg = document.getElementById('canvasPic')
          aImg.style = 'width: 100%;height: auto;-webkit-touch-callout: none;margin-left: 20'
          aImg.src = this.imgUrl
          console.log(blob)
        }, 'image/png')
      })
    },
    closeMask() {
      var imgBoxEle = document.getElementsByClassName('imgBox')[0]
      imgBoxEle.style.display = "none"
    }
  }
}
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}

.headers {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}

.header {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

#aaa {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.SetText {
  width: 90%;
  height: 35px;
  display: flex;
  align-items: center;
  margin: auto;
}

.propertyitemFrist {
  height: 35px;
  display: flex;
  align-items: center;
  margin: auto;
}

.SetTextSecond {
  width: 90%;
  height: 30px;
  margin: auto;
  display: flex;
  margin-top: 1px;
  // background-color: red;
  align-items: center;
}

.propertyitem {
  height: 30px;
  display: flex;
  align-items: center;
  margin: auto;
}

.headimg {
  width: 50px;
  height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.totalResult {
  width: 90%;
  height: 120px;
  display: flex;
  justify-content: center;
  margin: auto;
  border: 1px solid rgb(50, 136, 170);
  border-radius: 10px;
  margin-top: 10px;
}

.left-div {
  width: 30px;
  height: 27px;
  line-height: 27px;
  text-align: center;
}

.img {
  width: 27px;
  height: 27px;
}

.cell-bg {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  border-bottom: 1px solid rgb(230, 230, 230);
  margin-bottom: 1px;
}

.right-div {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  margin-left: 5px;
  font-size: 14px;
}

.content {
  margin-left: 5px;
  margin-right: 20px;
  color: black;
  font-size: 14px;
}

//长按保存图片
.imgBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  color: #607d8b;
  text-align: center;
  z-index: 10000;

  .imgMain {
    position: relative;
    margin: 0;
    padding: 0 0 15px 0;
    width: 100%;
    max-width: 750px;
    min-width: 300px;
    height: auto;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    p {
      padding: 10px 0px;
    }

    .canvasImg {
      max-height: 100vh;
      position: relative;
      margin-left: 15px;
      margin-right: 15px;
      overflow-x: hidden;
      overflow-y: auto;

      img {
        width: 100%;
        height: auto;
        background: #525252;
      }
    }
  }
}
#inputview {
  width: 150px;
  height: auto;
  margin-right: 20rpx;
  text-align: center;
  font-size: 15px;
  background-color: white;
  border: none;
  border-bottom: 1px solid;
  outline: none;
}
</style>

